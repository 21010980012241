import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Back to `}<a parentName="p" {...{
        "href": "/Case-studies",
        "title": "Case-studies"
      }}>{`Case-studies`}</a>{``}</p>
    <h3>{`Greek Cities Use Polis for Thorny AirBnB Issue`}</h3>
    <p><strong parentName="p">{`Headline:`}</strong>{` Residents in two Greek cities—Athens and Thessaloniki—used Polis to brainstorm practical solutions to the AirBnB issue, and find common ground for regulation. `}<br /></p>
    <p><strong parentName="p">{`When:`}</strong>{` 2023 `}<br />{`
`}<strong parentName="p">{`Where:`}</strong>{` Greece `}<br />{`
`}<strong parentName="p">{`Size:`}</strong>{` 944 participants `}<br /></p>
    <p><strong parentName="p">{`Details:`}</strong>{` `}<br />{` `}</p>
    <p>{`In Greek cities, as in other European cities, the mushrooming of Airbnb properties has negatively affected local residents in numerous ways. It has exacerbated housing shortages and inflated rents; affected local affordability and transformed residential neighborhoods into tourist-centric zones. At the same time, one-third of Greece’s GDP comes from tourism, and a considerable number of local residents work in the tourism industry. This contradiction has fueled deep polarization on the issue. A nonprofit called AstyLab (City Lab, in Greek) used Polis to bring together a diverse group of city residents, to brainstorm practical solutions and find convergence, with the end goal of regulating AirBnb in Greece. `}<br /></p>
    <p><strong parentName="p">{`Background:`}</strong>{` `}<br /></p>
    <p>{`AstyLab (City Lab, in Greek) had its origins in a newsletter—“Take Back the City”— created by a concerned citizen and local businessman to broaden the public discourse around urban spaces in the Greek cities of Athens and Thessaloniki. As the newsletter grew, it attracted the attention of urban designers, data scientists, economists, and other professionals. Together, they started brainstorming on solutions to the issues Greek urban residents face daily. For example, the thorny issue of Airbnb and the congestion caused by cars in the old parts of the cities. After beginning to organize as a group in August 2023, they incorporated as a nonprofit called AstyLab (City Lab, in Greek) in 2024, and inspired by vTaiwan and Audrey Tang, started exploring the use of Polis. `}<br /></p>
    <p><strong parentName="p">{`Choosing an issue and setting goals:`}</strong>{` `}<br /></p>
    <p>{`AstyLab chose to first tackle the AirBnb issue because the conversation around AirBnB in Greece is polarized and emotionally charged. City residents tend to take strong sides without  considering other viewpoints .
AstyLab set two goals for their Polis conversation: (1) To uncover surprising areas of agreement in public opinion on the AirBnb issue and (2) Learn about the tool Polis and think of how it can be used to solve other issues faced by people in Greek urban areas`}</p>
    <p><strong parentName="p">{`Setting the stage:`}</strong>{` `}<br />{` `}</p>
    <p>{`A series of three newsletters went out: The first one provided an educational briefing about the AirBnB issue. The second one provided an overview of Polis and how it was used in Taiwan. The third newsletter integrated the contents of the first two newsletters and talked about how Polis could help in the AirBnB discussion. These three newsletters iteratively set the stage for the launch of a Polis conversation on the AirBnB issue, in Athens and Thessaloniki. `}<br /></p>
    <p><strong parentName="p">{`Launching the conversation:`}</strong>{` `}<br /></p>
    <p>{`AstyLab set up the Polis conversation, and phased the inflow of participants in the following order:`}<br /></p>
    <ul>
      <li parentName="ul">{`In a pre-launch workshop, the link to the Polis conversation was shared on a WhatsApp group of 40-50 AstyLab members. This inner circle of  participants was formed by those who followed a newsletter link to sign up to "help the cause," indicating that they are quite active members. To this core group, key partners and experts were also added.
helped write seed statements and voted on each other’s statements, generating initial votes and feedback on the seed statements. The feedback was used to re-phrase or add context to statements, if needed. `}</li>
      <li parentName="ul">{`The  Polis link with all the seed statements and initial voting was then sent through the “Take Back The City” newsletter to roughly 2000 readers. `}</li>
      <li parentName="ul">{`After 4 days, the Polis link was promoted on the social media accounts of individual AstyLab members and the official accounts of AstyLab. These accounts have a total of ~6000 followers. After about 4 days, AstyLab purchased ads on Instagram and Facebook. After a period of trial and error, AstyLab got new participants to vote for roughly 7 or 8 Euro cents.`}</li>
      <li parentName="ul">{`The entire conversation ran about 4 weeks, from 08 November 2023 to 10 December 2023.`}
        <br />
      </li>
    </ul>
    <p><strong parentName="p">{`During the conversation:`}</strong>{` `}<br />{` `}</p>
    <ul>
      <li parentName="ul">{`Throughout the Polis conversation participants could see the majority opinions, opinion groupings and statements that defined each group, via the visualization on the Polis interface. `}</li>
      <li parentName="ul">{`AstyLab published a google spreadsheet with a daily update of all the statements submitted, including the ones that were moderated out. For the statements that were moderated out, they added their reasons for rejecting the statements. These reasons were also detailed and published in their moderation policy, which they built from `}<a parentName="li" {...{
          "href": "https://compdemocracy.org/Moderation/"
        }}>{`CompDem’ official  moderation recommendations`}</a>{`. `}</li>
      <li parentName="ul">{`AstyLab communicated all the results, in full, through all it’s communication channels`}</li>
      <li parentName="ul">{`AstyLab used the demographic and geographic data from the ads to show the aggregate gender, age ranges and geographic locations of the  Polis participants. 75% of the total participants were recruited through the  ads and 25% came from the AstyLab community. `}<br /></li>
    </ul>
    <p><strong parentName="p">{`Results:`}</strong>{` `}<br />{` `}</p>
    <p>{`The Polis results revealed convergence on some points that could form the basis of fruitful  discussions and policies. Both groups voted by more than 70%, that tax relief or other incentives should be given to landlords who make their homes available for long-term rentals in areas with intense Airbnb presence. Both groups voted by more than 75%, that owners of Airbnb apartments should contribute to the costs of maintaining the whole apartment building and not behave as a "free riders". Both groups voted by more than 90%, that the state should provide the housing for teachers, doctors, etc in tourist destinations that they are being priced out of. AstyLab is writing a white paper to highlight the areas of consensus uncovered by Polis and point to existing regulations on AirBnB that have been effective on these points in other municipalities around Europe. `}</p>
    <h4>{`Links:`}<br /></h4>
    <p>{`Before: `}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://adammarkakis.substack.com/p/airbnb-12"
        }}>{`Newsletter post about the AirBnB issue`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.instagram.com/p/CyTcZ4gID42/?img_index=1"
        }}>{`Instagram/Facebook post about the AirBnb issue`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://adammarkakis.substack.com/p/airbnb-23"
        }}>{`Newsletter post about Polis in Taiwan`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.instagram.com/p/CzgB8Gao6Cd/"
        }}>{`Instagram/Facebook post announcing the Polis conversation  (& first ad iteration)`}</a></li>
    </ul>
    <p>{`During: `}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "src/images/whatsapp_community_launch_astylab.png"
        }}>{`SCREENSHOT: Polis pre-launch workshop in AstyLab WhatsApp group`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://adammarkakis.substack.com/p/airbnb-33"
        }}>{`Newsletter launch`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.instagram.com/p/CzwOMFMIKE7/"
        }}>{`Instagram/Facebook launch`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "src/images/Astylab_Polis_ads.png"
        }}>{`SCREENSHOT: Ads purchase and reach`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "src/images/Astylab_polis_ads_audience_fb.png"
        }}>{`SCREENSHOT: Ads demographic reach on facebook`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "src/images/Astylab_polis_ads_audience_instagram.png"
        }}>{`SCREENSHOT: Ads demographic reach on Instagram`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://adammarkakis.substack.com/p/airbnb"
        }}>{`Newsletter with preliminary Polis results`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://docs.google.com/spreadsheets/d/1DbnVEUbmtLDTny8zf9c4BHkiSx2X9WKIOE3HAI9CbHg/edit?usp=sharing"
        }}>{`Google spreadsheet with comments and moderation details`}</a></li>
    </ul>
    <p>{`After: `}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.astylab.gr/home/polis-airbnb"
        }}>{`Project website`}</a></li>
      <li parentName="ul">{`Polis report: `}<a parentName="li" {...{
          "href": "https://pol.is/report/r3bdjsxsvxsybtnmemruw"
        }}>{`https://pol.is/report/r3bdjsxsvxsybtnmemruw`}</a></li>
      <li parentName="ul">{`Open data: `}{`[COMING]`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      